import React from "react"
import pluginHeader from "../../../assets/images/products/netversys/plugins_header.svg"
import wmPlugins from "../../../assets/images/products/netversys/wm-plugins.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"

const Plugins = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="services-details-image">
                <img src={pluginHeader} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Plugins und Dokumente</h1>
                <p>
                  Ein Pluginsystem macht den Unterschied aus zwischen einer Software, die genau für einen Zweck erstellt
                  worden und darüber hinaus nicht sehr flexibel ist und einem Enterprise System. NETVERSYS bezeichnet
                  sich von Beginn an als Enterprise Versandsystem, da die Grenzen zwischen Versandmanagement und anderen
                  Aufgaben in NETVERSYS verschwinden. Mit Hilfe des Pluginsystems ist es möglich, komplett neue
                  Funktionen und Bildschirmdialoge in NETVERSYS zu integrieren, ohne das eigentliche Produkt anzufassen.
                  Kunden nutzen dieses Pluginsystem um WMS Funktionalitäten direkt in NETVERSYS zu plazieren oder um
                  individuelle Auswertungen in das System zu bringen.
                </p>

                <WichtigeMerkmale items={[
                  "eigene Erweiterungen",
                  "Systemerweiterbarkeit",
                  "Unabhängigkeit von Produktroadmap",
                  "individuelle Prozesse abbildbar"
                ]} image={<img src={wmPlugins}
                               alt="Plugins Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection />

              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Plugins in der Praxis</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_plugins_framed.png"
                alt="Plugins Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Das Pluginsystem basiert auf speziellen Java Code. LOGENTIS bietet Dokumentationen und Beispielprojekte
                für die Entwicklung an. Ein einfaches Plugin ist innerhalb weniger Stunden entwickelt und durch
                einfaches kopieren des Java-Archivs in die Installation aktiviert. Sie wollen mehr Hilfe bei der
                Entwicklung des ersten Plugins? Kein Problem - LOGENTIS schult Ihre Entwickler live bei Ihnen vor Ort.
                Falls Sie bevorzugen, das Thema extern lösen zu lassen, übernimmt LOGENTIS die Entwicklung des Plugins
                selbstverständlich gern für Sie. Im folgenden Screenshot sehen Sie, dass ein Plugin automatisch in das
                NETVERSYS Menü integriert wurde. Das Plugin ist in diesem Fall ein individueller Report von erstellten
                Sendungen mit NETVERSYS.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-23173a">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Feature Highlight</span>
          <h2>Dokumentenmanagement</h2>
          <Row className={"mt-5"}>
            <Col md={{ span: 5 }} xs={{ span: 12 }}>
              <p>
                Ein Versandsystem generiert viele Dokumente. Angefangen von Einlieferungslisten für die Carrier, über
                Zusammenfassungen von Abrechnungen, bis hin zu Gefahrgutformularen. NETVERSYS versendet die meisten
                Dokumente
                via Email an die relevanten Mitarbeiter für einen späteren Ausdruck oder zur reinen Information. Oft
                gibt es jedoch den Fall, dass Dokumente noch einmal ausgedruckt werden sollen oder der Mitarbeiter diese
                auf seinem PC nicht mehr findet. NETVERSYS archiviert alle Dokumente im eigenen Dokumentenmanager.
              </p>
              <p>
                Über die Suchfunktion können im Dokumentenmanager Schriftstücke leicht gefunden werden und wahlweise
                entweder online angeschaut werden oder aber durch einen Download auf den eigenen PC übertragen werden.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_documents_framed.png"
                alt="Dokumentenmanagement Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Plugins
