import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Plugins from "../../../components/ProductDetails/netversys/Plugins"
import Seo from "../../../components/App/Seo"

const PluginsPage = () => {
  return (
    <Layout>
      <Seo title={"Unendlich erweiterbar durch kundeneigene Plugins."}
           description={"Sie wollen unsere Versansoftware erweitern um eigene Prozesse " +
           "abbilden zu können? Mit dem Pluginsystem ist dies problemlos " +
           "darstellbar."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Plugins",
                  "item": "https://logentis.de/versandsoftware/netversys/plugins/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Plugins />
      <Footer />
    </Layout>
  )
}

export default PluginsPage
